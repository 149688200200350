.info {
    .about_me {
        h1 {
            margin: 100px 0;
        }

        color: white;
        font-size: em(25);
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        margin: 50px 120px;
        line-height: 40px;
    }

    .copyright2 {
        color: $copyright-color;
        font-size: 1em;
        display: block;
        text-align: center;
        margin-bottom: 30px;
    }

    .menu_hamburger {
        background: rgba(141, 141, 156, 0.9);
    }
    
    header {
        background-color: rgba(141, 141, 156, 0.692);
    }

    @media screen and (max-width: 700px) {
        
        background-position: 75%;
        .about_me {
            margin: 40px 60px;
            h1 {
                margin: 60px 0;
            }
        }
    }

    @media screen and (max-width: 450px) {
        .about_me {
            margin: 40px 40px;
            font-size: em(20);
            line-height: 30px;
        }
    }
}