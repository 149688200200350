    //VARIABLES

$name-color: whitesmoke;
$header-background: rgba(255, 255, 255, 0.363);
$menu_a_color: white;
$hamburger-logo-color: white;
$menu-hamburger-color: rgba(255, 255, 255, 0.3);
$menu-hamburger-a: white;
$job-color: whitesmoke;
$copyright-color: whitesmoke;

    //FUNCTIONS

@function em($size, $context: 16) {
        @return ($size / $context) * 1em;
}

$page-colors: (
    'intro': url('../img-background/mach.jpg'),
    'gallery': black,
    'info': url('../img-background/cameras.jpg'),
    'contact': url('../img-background/kvety.jpg')
);
  
@each $page, $color in $page-colors {
    .#{$page} {       
            background: $color;
            height: 100%;
            background-repeat: no-repeat;
            background-position: top-left;
            background-size: cover;
            background-attachment: fixed;
            margin: 0;
            padding: 0;
            font-size: 100%;    
    }
}

html {
    box-sizing: border-box;
}

*,
*:before,
 :after {
    box-sizing: inherit;
}
 
    //-----HEADER-----//

    //NAME

.name {
    font-family: Arial, Helvetica, sans-serif;
    color: $name-color;
    font-size: em(30);
    font-weight: 300;
    margin: 0;
}

header {
    display: flex;
    justify-content: space-around;
    background-color: rgba(102, 139, 71, 0.774);
    border-bottom: 1px solid white;
    align-items: center;

    //NAVBAR MENU

    .navbar_menu {
        li {
            display: inline;
        }
    
        a {
            font-family: Arial, Helvetica, sans-serif;
            padding: em(10);
            margin: em(10);
            font-size: em(20);
            border-radius: 10px;
            text-decoration: none;
            color: $menu_a_color;
            border: solid 1px rgba(255, 255, 255, 0); 
        }
    
        a:hover, a:active {
            border: solid 1px white;
            background-color: lighten($header-background, 20%);
            color: darken($header-background, 90%);
        }
    }
    

    //ICON HAMBURGER

    .hamburger {
        position: relative;
        height: 50px;
        width: 50px;
        cursor: pointer;
        transition: 0.3s;
        z-index: 90;
        margin-right: 10px;

        span {
            position: absolute;
            height: 3px;
            width: 25px;
            background-color: $hamburger-logo-color;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: 0.3s;

            &:before {
                content: '';
                position: absolute;
                top: -8px;
                background-color: $hamburger-logo-color;
                height: 3px;
                width: 25px;
                transition: 0.3s;
            }

            &:after {
                content: '';
                position: absolute;
                top: 8px;
                background-color: $hamburger-logo-color;
                height: 3px;
                width: 25px;
                transition: 0.3s;
            }
        }        
    }

    .hamburger.active span {
        background-color: transparent;
    }
    .hamburger.active span:before {
        transform: rotate(45deg);
        top: 0;
    }
    .hamburger.active span:after {
        transform: rotate(-45deg);
        top: 0;
    }
}

    //MENU HAMBURGER

.menu_hamburger {
    z-index: 9999;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    background: rgba(102, 139, 71, 0.774);
    position: absolute;
    text-align: center;
    
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding: 15px 0 15px 0;
        border-bottom: #dddddd 1px solid;
        &:hover, &:active {
            display: block;
            background-color: lighten($menu-hamburger-color, 10%);
            padding: 15px 0 15px 0;
            border-bottom: white 1px solid;
        }
    }

    a {
        text-decoration: none;
        color: $menu-hamburger-a;
    }
}

    //-----CONTENT------//

.job {
    text-align: center;
    font-family: 'Spartan', sans-serif;   
    color: $job-color;
    position: absolute;
    font-size: em(50);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

    //FOOTER

.copyright {
    color: $copyright-color;
    font-size: 1em;
    position: absolute;
    top: 92%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
}

    //MEDIA SCREEN

@media screen and (max-width: 900px) {
    .job {
        font-size: em(40);
    }
}

@media screen and (max-width: 750px) {
    .job {
        font-size: em(30);
    }
    
}

@media screen and (max-width: 800px) {
    .navbar_menu {
        display: none;
    }
    header {
        justify-content: space-between;
    }
    .name {
        margin: 15px;
    }
}

@media screen and (max-width: 650px) {
    .name {
        font-size: em(25);
    }
}

@media screen and (max-width: 600px) {
    .name {
        margin: 15px;
        font-size: em(22);
    }
    .intro {
        background-position: 95%;
    }
}

@media screen and (min-width: 800px) {
    .hamburger,
    .menu_hamburger {
        display: none;
    }
}

    //IMPORTS

@import "gallery";
@import "about-me";
@import "contact";




