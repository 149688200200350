.gallery {
    header {
        background-color: rgb(109, 106, 106);
    }
    .menu_hamburger {
        background: rgba(0, 0, 0, 0.8);
    }

    #overlay {
        background: black;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 95;
        text-align: center;
    }

    #overlay img {
        margin: 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid #fff;
        border-radius: 5px;
        max-width: 100%;
        max-height: 100%;
    }

    .set_gallery {
        font-family: Arial, Helvetica, sans-serif;
        min-height: 100vh;
        padding-bottom: 100px;
        text-transform: capitalize;

        .image_container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .close {
                position: fixed;
                top: 15px;
                right: 35px;
                color: #f1f1f1;
                font-size: 40px;
                font-weight: bold;
                transition: 0.3s;
                z-index: 99;
                cursor: pointer;
            }

            .image {
                height: 350px;
                width: 350px;
                overflow: hidden;
                border: solid 10px #fff;
                border-radius: 5px;
                box-shadow: 0 3px 5px rgba(0,0,0,.3);
                margin: 20px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.4);
                        transition:all .2s cubic-bezier(.34,1.12,.68,1.31);                
                    }
                }
            }
        }

        .controls {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 20px 0;
            list-style: none;

            .buttons {
                height: 40px;
                width: 140px;
                background-color: #fff;
                color: black;
                border-radius: 10px;
                border: solid 2px #fff;
                font-size: 20px;
                line-height: 35px;
                cursor: pointer;
                margin: 20px;
                text-align: center;
                &:hover {
                    background-color: lighten(rgb(212, 187, 44), 35%);
                }
            }

            .buttons.active {
                background: rgb(212, 187, 44);
                color: #fff;
            }
        }
    }

    .copyright3 {
        color: $copyright-color;
        font-size: 1em;
        display: block;
        text-align: center;
        margin-bottom: 30px;
    }
}

