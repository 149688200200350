.contact {
    background-position: 0;

    .menu_hamburger {
        background: rgba(134, 86, 77, 0.829);
    }

    header {
        background-color: rgba(134, 86, 77, 0.829);
    }

    .contact_data {
        text-align: center;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        
        a {
            color: $job-color;
            text-decoration: none;
            font-size: em(30);
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
                &:hover, &:active {
                color: rgb(255, 255, 208);
            }
        }
    }
}

@media screen and (max-width: 1220px) {
    .contact .contact_data {
        top: 35%;
        font-size: em(11);
    }    
}