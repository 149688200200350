html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.intro {
  height: 100%;
  background: url("mach.982cff2d.jpg") no-repeat;
  background-position: top-left;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.gallery {
  height: 100%;
  background: #000 no-repeat;
  background-position: top-left;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.info {
  height: 100%;
  background: url("cameras.bd008bb6.jpg") no-repeat;
  background-position: top-left;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.contact {
  height: 100%;
  background: url("kvety.aa8b90a5.jpg") no-repeat;
  background-position: top-left;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

.name {
  color: #f5f5f5;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.875em;
  font-weight: 300;
}

header {
  background-color: #668b47c5;
  border-bottom: 1px solid #fff;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

header .navbar_menu li {
  display: inline;
}

header .navbar_menu a {
  color: #fff;
  border: 1px solid #fff0;
  border-radius: 10px;
  margin: .625em;
  padding: .625em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25em;
  text-decoration: none;
}

header .navbar_menu a:hover, header .navbar_menu a:active {
  color: #1a1a1a5d;
  background-color: #ffffff5d;
  border: 1px solid #fff;
}

header .hamburger {
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 90;
  margin-right: 10px;
  transition: all .3s;
  position: relative;
}

header .hamburger span {
  height: 3px;
  width: 25px;
  background-color: #fff;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header .hamburger span:before {
  content: "";
  height: 3px;
  width: 25px;
  background-color: #fff;
  transition: all .3s;
  position: absolute;
  top: -8px;
}

header .hamburger span:after {
  content: "";
  height: 3px;
  width: 25px;
  background-color: #fff;
  transition: all .3s;
  position: absolute;
  top: 8px;
}

header .hamburger.active span {
  background-color: #0000;
}

header .hamburger.active span:before {
  top: 0;
  transform: rotate(45deg);
}

header .hamburger.active span:after {
  top: 0;
  transform: rotate(-45deg);
}

.menu_hamburger {
  z-index: 9999;
  width: 100%;
  text-align: center;
  background: #668b47c5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  position: absolute;
}

.menu_hamburger ul {
  margin: 0;
  padding: 0;
}

.menu_hamburger li {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  display: block;
}

.menu_hamburger li:hover, .menu_hamburger li:active {
  background-color: #ffffff4d;
  border-bottom: 1px solid #fff;
  padding: 15px 0;
  display: block;
}

.menu_hamburger a {
  color: #fff;
  text-decoration: none;
}

.job {
  text-align: center;
  color: #f5f5f5;
  font-family: Spartan, sans-serif;
  font-size: 3.125em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copyright {
  color: #f5f5f5;
  text-align: center;
  font-size: 1em;
  position: absolute;
  top: 92%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 900px) {
  .job {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 750px) {
  .job {
    font-size: 1.875em;
  }
}

@media screen and (max-width: 800px) {
  .navbar_menu {
    display: none;
  }

  header {
    justify-content: space-between;
  }

  .name {
    margin: 15px;
  }
}

@media screen and (max-width: 650px) {
  .name {
    font-size: 1.5625em;
  }
}

@media screen and (max-width: 600px) {
  .name {
    margin: 15px;
    font-size: 1.375em;
  }

  .intro {
    background-position: 95%;
  }
}

@media screen and (min-width: 800px) {
  .hamburger, .menu_hamburger {
    display: none;
  }
}

.gallery header {
  background-color: #6d6a6a;
}

.gallery .menu_hamburger {
  background: #000c;
}

.gallery #overlay {
  width: 100%;
  height: 100%;
  z-index: 95;
  text-align: center;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.gallery #overlay img {
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery .set_gallery {
  min-height: 100vh;
  text-transform: capitalize;
  padding-bottom: 100px;
  font-family: Arial, Helvetica, sans-serif;
}

.gallery .set_gallery .image_container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gallery .set_gallery .image_container .close {
  color: #f1f1f1;
  z-index: 99;
  cursor: pointer;
  font-size: 40px;
  font-weight: bold;
  transition: all .3s;
  position: fixed;
  top: 15px;
  right: 35px;
}

.gallery .set_gallery .image_container .image {
  height: 350px;
  width: 350px;
  border: 10px solid #fff;
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 3px 5px #0000004d;
}

.gallery .set_gallery .image_container .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery .set_gallery .image_container .image img:hover {
  transition: all .2s cubic-bezier(.34, 1.12, .68, 1.31);
  transform: scale(1.4);
}

.gallery .set_gallery .controls {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  list-style: none;
  display: flex;
}

.gallery .set_gallery .controls .buttons {
  height: 40px;
  width: 140px;
  color: #000;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  margin: 20px;
  font-size: 20px;
  line-height: 35px;
}

.gallery .set_gallery .controls .buttons:hover {
  background-color: #f2ebc0;
}

.gallery .set_gallery .controls .buttons.active {
  color: #fff;
  background: #d4bb2c;
}

.gallery .copyright3 {
  color: #f5f5f5;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1em;
  display: block;
}

.info .about_me {
  color: #fff;
  text-align: center;
  margin: 50px 120px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5625em;
  line-height: 40px;
}

.info .about_me h1 {
  margin: 100px 0;
}

.info .copyright2 {
  color: #f5f5f5;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1em;
  display: block;
}

.info .menu_hamburger {
  background: #8d8d9ce6;
}

.info header {
  background-color: #8d8d9cb0;
}

@media screen and (max-width: 700px) {
  .info {
    background-position: 75%;
  }

  .info .about_me {
    margin: 40px 60px;
  }

  .info .about_me h1 {
    margin: 60px 0;
  }
}

@media screen and (max-width: 450px) {
  .info .about_me {
    margin: 40px;
    font-size: 1.25em;
    line-height: 30px;
  }
}

.contact {
  background-position: 0;
}

.contact .menu_hamburger {
  background: #86564dd3;
}

.contact header {
  background-color: #86564dd3;
}

.contact .contact_data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact .contact_data a {
  color: #f5f5f5;
  font-family: Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif;
  font-size: 1.875em;
  text-decoration: none;
}

.contact .contact_data a:hover, .contact .contact_data a:active {
  color: #ffffd0;
}

@media screen and (max-width: 1220px) {
  .contact .contact_data {
    font-size: .6875em;
    top: 35%;
  }
}

/*# sourceMappingURL=index.07444c48.css.map */
